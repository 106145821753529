<template>
    <div>
        <nav class="navbar bg-white navbar-expand-lg navbar-light border-bottom">
            <div class="container">
                <router-link class="navbar-brand mr-auto" :to="{ name: 'boatsIndex' }"><img src="/assets/images/logo.jpg"></router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <template v-if="user">
                            <span class="navbar-text"><span class="badge badge-pill badge-info mr-2">{{ user.name }}</span></span>
                            <li class="nav-item">
                                <router-link class="nav-link mr-2" :to="{ name: 'boatsIndex' }"><i class="fas fa-ship"></i> Boats</router-link>
                            </li>
                            <li class="nav-item" v-if="user.type === 'admin'">
                                <router-link class="nav-link mr-2" :to="{ name: 'categoriesIndex' }"><i class="fas fa-paint-roller"></i> All Job Categories</router-link>
                            </li>
                            <li class="nav-item" v-if="user.type === 'admin'">
                                <router-link class="nav-link mr-2" :to="{ name: 'usersIndex' }"><i class="fas fa-users-cog"></i> All Users</router-link>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" @click="logout"><i class="fas fa-sign-out-alt"></i> Logout</a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div><!-- end of container -->
        </nav>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Nav",
    methods: {
        logout() {
            axios.defaults.headers.common['Authorization'] = '';
            localStorage.removeItem('token');
            this.$store.dispatch('user', null);
            this.$router.push({name: 'usersAuthenticate'});
        },
    },
    computed: {
        ...mapGetters(["user"])
    }
}
</script>
