export function getAuthToken() {
    return localStorage.getItem('token');
}

export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = '';
    localStorage.removeItem('token');
    this.$store.dispatch('user', null);
}

export function isLoggedIn() {
    return getAuthToken();
}

export function checkToken(response) {
    if (response.data.status === 'Token is Expired') {
        clearAuthToken();
        return true;
    }
    return false;
}
