require('./bootstrap');

import Vue from 'vue';
import VueRouter from 'vue-router';
import router from "./routes";
import store from "./vuex";
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { ValidationObserver } from 'vee-validate';
import Notifications from 'vue-notification';
import Index from "./Index";
import VueConfirmDialog from 'vue-confirm-dialog';
import 'vue-date-pick/dist/vueDatePick.css';

window.Vue = require('vue');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

Vue.mixin({
    methods: {
        checkAccessHelper: function () {
            if (this.$store.getters.user) {
                if (this.$store.getters.user.type !== this.$route.meta.allowUserType) {
                    this.$notify({
                        group: 'notifications',
                        type: 'error',
                        duration: 4000,
                        title: 'Access Denied',
                        text: 'Only admins can access this resource'
                    });
                    this.$router.push({name: 'boatsIndex'});
                }
            }
        },
        isUserAdmin: function () {
            if (this.$store.getters.user && this.$store.getters.user.type === 'admin') {
                return true;
            }
            return false;
        }
    },
});

const app = new Vue({
    el: '#app',
    router,
    store,
    components: {
        "index": Index
    }
});
