import VueRouter from "vue-router";
import {isLoggedIn} from "./utils";

const routes = [
    {
        path: "/users",
        component: () => import('./views/Users/Index'),
        children: [
            {
                path: '/',
                name: 'usersIndex',
                meta: {
                    title: 'All Users',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Users/UsersIndex'),
            },
            {
                path: '/register',
                name: 'usersRegister',
                meta: {
                    title: 'Register User',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Users/UsersRegister'),
            },
            {
                path: "/authenticate",
                name: "usersAuthenticate",
                meta: {
                    title: 'Login User',
                    allowAnonymous: true
                },
                component: () => import('./views/Users/UsersAuthenticate')
            },
            {
                path: "/change-password/:id",
                name: "changePassword",
                meta: {
                    title: 'Change Password',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Users/ChangePassword')
            },
            {
                path: '/user/:id/change-email',
                name: 'changeEmail',
                meta: {
                    title: 'Change email',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Users/ChangeEmail')
            }
        ]
    },
    {
        path: "/boats",
        component: () => import('./views/Boats/Index'),
        children: [
            {
                path: '/',
                name: 'boatsIndex',
                meta: {
                    title: 'All Boats',
                    allowAnonymous: false
                },
                component: () => import('./views/Boats/BoatsIndex')
            },
            {
                path: '/boats/create',
                name: 'boatsCreate',
                meta: {
                    title: 'Add new Boat',
                    allowAnonymous: false
                },
                component: () => import('./views/Boats/BoatsCreate')
            },
            {
                path: '/boats/:id',
                name: 'boatsShow',
                meta: {
                    title: 'Show Boat',
                    allowAnonymous: false
                },
                component: () => import('./views/Boats/BoatsShow')
            },
            {
                path: '/boats/:id/edit',
                name: 'boatsEdit',
                meta: {
                    title: 'Edit Boat',
                    allowAnonymous: false
                },
                component: () => import('./views/Boats/BoatsEdit')
            }
        ]
    },
    {
        path: "/categories",
        component: () => import('./views/Categories/Index'),
        children: [
            {
                path: '/',
                name: 'categoriesIndex',
                meta: {
                    title: 'All Categories',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Categories/CategoriesIndex')
            },
            {
                path: '/categories/create',
                name: 'categoriesCreate',
                meta: {
                    title: 'Add new Category',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Categories/CategoriesCreate')
            },
            {
                path: '/categories/:id',
                name: 'categoriesShow',
                meta: {
                    title: 'Show Category',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Categories/categoriesShow')
            },
            {
                path: '/categories/:id/edit',
                name: 'categoriesEdit',
                meta: {
                    title: 'Edit Category',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Categories/categoriesEdit')
            }
        ]
    },
    {
        path: "/jobs",
        component: () => import('./views/Jobs/Index'),
        children: [
            {
                path: '/jobs/:id/create',
                name: 'jobsCreate',
                meta: {
                    title: 'Create job',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Jobs/JobsCreate')
            },
            {
                path: '/jobs/:id/edit',
                name: 'jobsEdit',
                meta: {
                    title: 'Edit job',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Jobs/JobsEdit')
            }
        ]
    },
    {
        path: "/search",
        component: () => import('./views/Search/Index'),
        children: [
            {
                path: '/search/by-date',
                name: 'searchByDate',
                meta: {
                    title: 'Search transactions by dates',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Search/TransactionsByDates')
            }
        ]
    },
    {
        path: "/transactions",
        component: () => import('./views/Transactions/Index'),
        children: [
            {
                path: '/transactions/:id',
                name: 'transactionsIndex',
                meta: {
                    title: 'Transactions',
                    allowAnonymous: false
                },
                component: () => import('./views/Transactions/TransactionsIndex')
            },
            {
                path: '/transactions/:id/create',
                name: 'transactionsCreate',
                meta: {
                    title: 'Create Transaction',
                    allowAnonymous: false
                },
                component: () => import('./views/Transactions/TransactionsCreate')
            },
            {
                path: '/transactions/:id/edit',
                name: 'transactionsEdit',
                meta: {
                    title: 'EditTransaction',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Transactions/TransactionsEdit')
            },
            {
                path: '/receivables/:id/edit',
                name: 'receivablesEdit',
                meta: {
                    title: 'EditTransaction',
                    allowAnonymous: false,
                    allowUserType: 'admin'
                },
                component: () => import('./views/Transactions/TransactionsReceivableEdit')
            },
            {
                path: '/transactions/:id/receivable',
                name: 'transactionsReceivable',
                meta: {
                    title: 'Receivable Transaction',
                    allowAnonymous: false
                },
                component: () => import('./views/Transactions/TransactionsReceivable')
            },
            {
                path: '/transactions/:id/receipt/:boat_id',
                name: 'transactionsReceipt',
                meta: {
                    title: 'Upload Receipt',
                    allowAnonymous: false
                },
                component: () => import('./views/Transactions/TransactionsReceipt')
            }
        ]
    }
];

const router = new VueRouter({
    routes, // short for `routes: routes`
    mode: "history"
});

router.beforeEach((to, from, next) => {
    if (to.name == 'usersAuthenticate' && isLoggedIn()) {
        next({ path: '/boats' })
    }
    else if (!to.meta.allowAnonymous && !isLoggedIn()) {
        next({
            path: '/authenticate',
            query: { redirect: to.fullPath }
        })
    }
    else {
        next()
    }
});

export default router;
