<template>
    <div>
        <notifications group="notifications" />
        <vue-confirm-dialog></vue-confirm-dialog>

        <Nav/>

        <div class="container my-4 px-4">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import Nav from "./components/Nav";
    import {checkToken} from "./utils";

    export default {
        name: "Index",
        components: {Nav},
        data() {
            return {
                user: {}
            }
        },
        created() {
            this.getUser();
        },
        methods: {
            getUser: function () {
                axios.get('/api/user')
                    .then(response => {
                        if (response.data.status === 'Token is Expired') {
                            axios.defaults.headers.common['Authorization'] = '';
                            localStorage.removeItem('token');
                            this.$store.dispatch('user', null);
                            this.$router.push({name: 'usersAuthenticate'});
                            return;
                        }
                        this.$store.dispatch('user', response.data.user);
                    })
                    .catch(error => {
                        this.$notify({
                            group: 'notifications',
                            type: 'error',
                            duration: 4000,
                            title: 'System Error',
                            text: 'Unable to retrieve user from database'
                        });
                        console.log(error);
                    });
            },
            // isLoggedIn: function () {
            //     const authToken = localStorage.getItem('token');
            //     if (!authToken) {
            //         this.$router.push({name: 'usersAuthenticate'});
            //     }
            // }
        }
    }
</script>
